var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('order-search',{attrs:{"start":_vm.startDate,"end":_vm.endDate},on:{"handle-start":_vm.handleStart,"handle-end":_vm.handleEnd,"handle-search":_vm.getData}}),_c('order-tabs',{attrs:{"tabs-list":_vm.tabList},on:{"tab-click":_vm.getData},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}}),_c('center-order-table',{attrs:{"table-title":"摄影订单","table-list":_vm.orderTableList},scopedSlots:_vm._u([{key:"price",fn:function(ref){
var price = ref.price;
return [_c('div',[_vm._v(_vm._s("¥ " + price))])]}},{key:"payment",fn:function(ref){
var price = ref.price;
return [_c('div',[_vm._v(_vm._s("¥ "+ price))])]}},{key:"status",fn:function(ref){
var status = ref.status;
var id = ref.id;
return [_c('div',[_vm._v(_vm._s(_vm._f("ostatus")(status)))]),_c('router-link',{attrs:{"to":{
                        name: 'photographPay',
                        query:{id: id}
                    }}},[_vm._v(" 订单详情 ")])]}},{key:"handle",fn:function(ref){
                    var row = ref.row;
return [_c('div',[(row.orderStatus == 0)?_c('a',{staticClass:"center-photography-handle",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$router.push({name: 'photographPay',query: {id:row.orderID}})}}},[_vm._v(" 支付 ")]):_vm._e(),(row.orderStatus == 2)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.takedPhoto==0),expression:"row.takedPhoto==0"}],staticClass:"center-photography-handle",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleConfirm(row.orderID)}}},[_vm._v(" 确认服务 ")]):_vm._e(),(row.orderStatus == 2)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.takedPhoto==1),expression:"row.takedPhoto==1"}],staticClass:"center-photography-handle",attrs:{"href":"javascript:;"}},[_vm._v(" 已确认 ")]):_vm._e(),(row.orderStatus==3 || row.orderStatus==4 || row.orderStatus==9)?_c('a',{staticClass:"center-photography-handle",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.$router.push({
                            name:'sample',
                            query:{
                                status: row.sampleStatus,
                                id:row.orderID
                            }
                        })}}},[_vm._v(" "+_vm._s(row.orderStatus==9? "下载精片": "查看样片")+" ")]):_vm._e(),(row.orderStatus == 5)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.completed==0),expression:"row.completed==0"}],staticClass:"center-photography-handle",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleStatus(row.orderID)}}},[_vm._v(" 订单完成 ")]):_vm._e(),(row.orderStatus == 5)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(row.completed==1),expression:"row.completed==1"}],staticClass:"center-photography-handle",attrs:{"href":"javascript:;"}},[_vm._v(" 已确认完成 ")]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }