<template>
    <!-- 摄影订单 -->
    <div>
        <!-- 搜索栏 & 标签页 -->
        <order-search
            :start="startDate"
            :end="endDate"
            @handle-start="handleStart"
            @handle-end="handleEnd"
            @handle-search="getData">
        </order-search>
        <order-tabs
            v-model="tab"
            :tabs-list="tabList"
            @tab-click="getData">
        </order-tabs>
        <!-- 表格 -->
        <center-order-table
            table-title="摄影订单"
            :table-list="orderTableList">
            <!-- 价格 -->
            <template #price="{price}">
                <div>{{"¥ " + price}}</div>
            </template>
            <!-- 实付款 -->
            <template #payment="{price}">
                <div>{{"¥ "+ price}}</div>
            </template>
            <!-- 交易状态 -->
            <template #status="{status, id}">
                <div>{{status | ostatus}}</div>
                <router-link
                    :to="{
                        name: 'photographPay',
                        query:{id}
                    }">
                    订单详情
                </router-link>
            </template>
            <!-- 操作 -->
            <template #handle="{row}">
                <!-- 个人订单 -->
                <div>
                    <!-- 待支付 -->
                    <a href="javascript:;"
                        class="center-photography-handle"
                        v-if="row.orderStatus == 0"
                        @click="$router.push({name: 'photographPay',query: {id:row.orderID}})">
                        支付
                    </a>
					<a href="javascript:;"
						v-show="row.takedPhoto==0"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 2"
						@click="handleConfirm(row.orderID)"
						>
					    确认服务
					</a>
					<a href="javascript:;"
						v-show="row.takedPhoto==1"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 2"
						>
					    已确认
					</a>

                    <!-- 查看样片 -->
                    <a href="javascript:;"
                        class="center-photography-handle"
                        v-if="row.orderStatus==3 || row.orderStatus==4 || row.orderStatus==9"
                        @click="$router.push({
                            name:'sample',
                            query:{
                                status: row.sampleStatus,
                                id:row.orderID
                            }
                        })">
                      {{row.orderStatus==9? "下载精片": "查看样片"}}
                    </a>
                    <!-- 下载地址 -->
                   <!-- <el-popover
                        placement="right"
                        trigger="click"
                        v-if="row.orderStatus == 5 || row.orderStatus == 9 && row.downloadUrl">
                        <el-link
                            :href="row.downloadUrl"
                            target="_blank"
                            type="primary"
                            :underline="false">
                            <span>{{row.downloadUrl}}</span>
                        </el-link>
                        <div style="margin-top: 10px">
                            <span>下载密码：</span>
                            <span>{{row.downloadKey}}</span>
                        </div>
                        <a href="javascript:;"
                            slot="reference"
                            class="center-photography-handle">
                            下载地址
                        </a>
                    </el-popover> -->
                    <!-- 完成订单 -->
                    <a href="javascript:;"
						v-show="row.completed==0"
                        class="center-photography-handle"
                        v-if="row.orderStatus == 5"
                        @click="handleStatus(row.orderID)">
                        订单完成
                    </a>
					<!-- 确认完成订单 -->
				<!-- 	<a href="javascript:;"
						v-show="row.completed==0"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 3"
						@click="photographyFinish(row.orderID)"
						>
					   确认完成
					</a>-->
					<a href="javascript:;"
						v-show="row.completed==1"
					    class="center-photography-handle"
					    v-if="row.orderStatus == 5"
						>
					   已确认完成
					</a>
                </div>
            </template>
        </center-order-table>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import order from '@/mixins/centerOrder'

export default {
    filters:{
        // 订单状态过滤器
        ostatus(val){
            switch(val){
                case 0:
                    return "待付款";
                    break;
                case 1:
                    return "待接单";
                    break;
                case 2:
                    return "待服务";
                    break;
                case 3:
                    return "样片上传";
                    break;
                case 4:
                    return "样片挑选";
                    break;
                case 5:
                    return "作品上传";
                    break;
                case 9:
                    return "已完成";
                    break;
                case -1:
                    return "已取消";
                    break;
				case -2:
				    return "已过期";
				    break;
            }
        },
    },
    mixins: [ order ],
    data() {
        return {
			tab:"-3",
            tabList: [
				{
				    value: "-3",
				    label: "全部",
				},
                {
                    value: "0",
                    label: "待付款",
                },
                {
                    value: "1",
                    label: "待接单",
                },
                {
                    value: "2",
                    label: "待服务",
                },
                {
                    value: "3",
                    label: "样片挑选",
                },
                // {
                //     value: "5",
                //     label: "下载",
                // },
                {
                    value: "9",
                    label: "完成订单",
                },
                {
                    value: "-1",
                    label: "已取消",
                },
				{
				    value: "-2",
				    label: "过期订单",
				},

            ]
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        orderTableList: state => state.order.orderTableList
    }),
    methods: {
        ...mapMutations([
            'handleLoading',
            'setCenterOrder'
        ]),
        ...mapActions([
            'getCenterOrder',
            'handleOrderStatus'
        ]),
        getParams() {
            let userID = this.user_id,
                fromTime = this.startDate,
                toTime = this.endDate,
                orderStatus = this.tab == '-3'? '': this.tab;
            return this.$qs.stringify({
                userID,
                fromTime,
                toTime,
                orderStatus,
                type: 2
            })
        },
		//确认完成订单
		photographyFinish(id){
			let status = 99,
			orderID = id,
			userID = this.user_id;
			this.handleSampleStatus({
			    status,
			    orderID,
				userID,

			}).then(res => {
					console.log(res)
			 })
		},
		//过滤出不同的数据
        getData() {
            let url = 'usercenter/bookorder',
                params = this.getParams();
            this.getCenterOrder({
                url,
                params
            })
        },
		handleConfirm(orderID) {
		    let url = 'usercenter/finishedshoot',
		        msg = '是否已经受到服务？',
		        params = this.$qs.stringify({
		            userID: this.user_id,
		            orderID,
		        });
		    this.handleAffirm(url, params, msg);
		},

		handleAffirm(url, params, msg) {
		    this.$confirm(msg, '提示' ,{
		        confirmButtonText: '确定',
		        cancelButtonText: '取消',
		        type: 'warning'
		    }).then(() => {
		        this.handleLoading(true);

		        return this.handleOrderStatus({
		            url,
		            params
		        })
		    }).then(res => {
		        let code = res.data.code == 200;

		        this.$message({
		            showClose: true,
		            message: res.data.message,
		            type: code? 'success': 'error'
		        })

		        code && this.getData();
		    }).catch(() => {
		    }).finally(() => {
		        this.handleLoading(false);
		    })
		},

        handleStatus(orderID) {
            this.$confirm('是否确定完成？', '提示' ,{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.handleLoading(true);

                let url = '/usercenter/finishedselect',
                    params = this.$qs.stringify({
                        userID: this.user_id,
                        orderID
                    });

                return this.handleOrderStatus({
                    url,
                    params
                })
            }).then(res => {
                let code = res.data.code == 200;
                this.$message({
                    showClose: true,
                    message: res.data.message,
                    type: code? 'success': 'error'
                })
                code && this.getData();
            }).catch(() => {
            }).finally(() => {
                this.handleLoading(false);
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.getData();
        })
    },
    beforeRouteLeave (to, from, next) {
        this.setCenterOrder([]);
        next();
    }
}
</script>
